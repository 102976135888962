import bowser from 'bowser';

// ---
// API functions
// ---

// ---
export function languageObsolete() {
    let lang = navigator.language;
    const splLang = lang.split('-');
    if (splLang.length > 1) {
        lang = splLang[0];
    }
    return lang;
};

export function deviceInfo() {
    const ua = navigator.userAgent;
    const browser = bowser.getParser(ua);
    let flag: string = "unknown";

    if (browser.satisfies({ chrome: '>=0', chromium: '>=0' }))
        flag = 'chrome';
    else if (browser.satisfies({ firefox: '>=0' }))
        flag = 'firefox';
    else if (browser.satisfies({ safari: '>=0' }))
        flag = 'safari';
    else if (browser.satisfies({ opera: '>=0' }))
        flag = 'opera';
    else if (browser.satisfies({ 'microsoft edge': '>=0' }))
        flag = 'edge';

    return {
        flag,
        name: browser.getBrowserName(),
        version: browser.getBrowserVersion(),
        engine: browser.getEngineName(),
        os: browser.getOSName(),
        platform: browser.getPlatformType(),
        origin: document.location.href,
        ua
    };
}

export async function trySessionRedirectRaw(resp: Response) {
    const respCode = resp.status;
    let respBody: any = {};
    try {
        respBody = await resp.json();
    } catch { }
    trySessionRedirect(respCode, respBody)
}


export async function trySessionRedirect(respStatus: number, respBody: any) {
    if (respStatus >= 500 && respStatus <= 599) {
        let location = respBody.location;
        if (location) {
            if (!location.match('^https?://')) {
                location = "https://" + location;
            };
            window.location.replace(location);
            //alert("");
        }
    }
}