import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';
import { Theme, Card, CardContent, TextField, Button, CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import './InviteRegisterPage.css';
import Consts from '../../utils/consts';
import { parseHashLocation } from '../../App';
import * as DataUtils from '../../stores/DataUtils';

// -----------
// Defines
// -----------
type state = {
    password: string,
    passwordRepeat: string,
    name: string,
    login: string,
    pageType: PageType
};

enum PageType {
    invite = 'invite',
    selfReg = 'selfReg',
    undefined = 'undefined'
};

enum ErrorType {
    ok = 'ok',
    error = 'error',
    undefined = 'undefined'
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'fixed',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            backgroundColor: '#EAEAEA',
            overflow: 'auto'
            // border: '1px solid black',
        },
        container2: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            minHeight: 600,
            width: '90%',
            maxWidth: '400px',
            // border: '1px solidenumblack',
        },
        card: {
            padding: '0 8px'
        },
        logo: {
            flex: '1 1 20%',
            paddingTop: 24,
            paddingLeft: 24,
            // border: '1px solid black',
        },
        logoImg: {
            padding: '0 0 0 0',
            margin: '0 0 0 0',
            maxHeight: 100,
            maxWidth: 200,
            // border: '1px solid black',
        },
        header: {
            marginTop: 16,
            paddingLeft: 24,
            paddingRight: 24,
            fontSize: '24px',
            // border: '1px solid green',
        },
        div: {
            // border: '1px solid red',
        },
        form: {
            padding: '0 0 0 0',
            // border: '1px solid black',
        },
        inner: {
            width: '200',
            display: 'flex',
            flexDirection: 'column',
            // border: '1px solid black',
        },
        textField: {
            marginLeft: 0,//theme.spacing(1),
            marginRight: 0,//theme.spacing(1),
            width: '100%',
        },
        linkField: {
            flex: '1',
            marginBottom: '0.5em',
            //marginLeft: 0,//theme.spacing(1),
            //marginRight: 0,//theme.spacing(1),
            color: 'blue'
        },
        error: {
            fontSize: '12px',
            color: 'red',
            marginLeft: 0
        },
        success: {
            fontSize: '12px',
            color: 'green',
            marginLeft: 0
        },
        divButton: {
            padding: '8 0',
            width: "100%",
            position: 'relative',
            marginBottom: '1em'
        },
        button: {
            width: '100%',
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -20,
        }
    }),
);

const InviteRegisterPage: React.FC = (props: any, prop2: any) => {
    const classes = useStyles();
    //
    const history: History = useHistory();
    const location = useLocation();
    //
    let pageType: PageType = PageType.undefined;
    let headerText: string = '';
    const pathName = location.pathname;
    if (pathName === '/registerSubmit') {
        pageType = PageType.selfReg;
        headerText = Consts.pages.inviteRegisterPage.headerSubmit;
    } else if (pathName === '/acceptInvite') {
        pageType = PageType.invite;
        headerText = Consts.pages.inviteRegisterPage.headerInvite;
    }
    //
    const hashLocation = parseHashLocation(location.search);
    const requestId: string = hashLocation.id;
    const domain: string = hashLocation.domain;
    //
    const defaultState: state = {
        password: '',
        passwordRepeat: '',
        name: hashLocation.name,
        login: hashLocation.login,
        pageType: pageType
    };
    const [state, setState] = useState(defaultState);
    const [error, setError] = useState({ text: "", type: ErrorType.undefined });
    const [redirecting, setRedirecting] = useState(false);

    const logo = "/rest/v1/public/resources/logo";

    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.container2}>
                    <Card className={classes.card}>
                        <div className={classes.logo}><img src={logo} alt="l" className={classes.logoImg} /></div>
                        <div className={classes.header}>{headerText}</div>
                        <div className={classes.div}>
                            <form className={classes.form} onSubmit={(e) => submitHandler(e, requestId, state, history, hashLocation, setError, setRedirecting)}>
                                <CardContent className={classes.inner} >
                                    <TextField
                                        id="domain"
                                        label={Consts.pages.inviteRegisterPage.domain}
                                        className={classes.textField}
                                        margin="normal"
                                        value={domain}
                                        disabled={true}
                                    />
                                    <TextField
                                        required
                                        id="name"
                                        label={Consts.pages.inviteRegisterPage.name}
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={e => setState({ ...state, name: e.target.value })}
                                        value={state.name}
                                        disabled={state.pageType === PageType.selfReg}
                                    />
                                    <TextField
                                        required
                                        id="login"
                                        label={Consts.pages.inviteRegisterPage.login}
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={e => setState({ ...state, login: e.target.value })}
                                        value={state.login}
                                        disabled={state.pageType === PageType.selfReg}
                                    />
                                    <TextField
                                        required
                                        id="pwd"
                                        label={Consts.pages.inviteRegisterPage.pwd}
                                        className={classes.textField}
                                        margin="normal"
                                        type="password"
                                        onChange={(e) => {
                                            setState({ ...state, password: e.target.value })
                                            isMatchPwd(state.passwordRepeat, e.target.value, setError);
                                        }}
                                    />
                                    <TextField
                                        required
                                        id="pwdRepeat"
                                        label={Consts.pages.inviteRegisterPage.pwdRepeat}
                                        className={classes.textField}
                                        margin="normal"
                                        type="password"
                                        onChange={(e) => {
                                            setState({ ...state, passwordRepeat: e.target.value })
                                            isMatchPwd(state.password, e.target.value, setError);
                                        }}
                                    />
                                    <div style={{ padding: '9px 0' }}>
                                        <p style={{ margin: '0' }} className={error.type === 'ok' ? classes.success : classes.error}>{error.text}</p>
                                    </div>
                                    <div className={classes.divButton}>
                                        <Button variant="contained" disabled={state.password === '' || state.password !== state.passwordRepeat || state.login === '' || redirecting} className={classes.button} type="submit">{Consts.pages.inviteRegisterPage.next}</Button>
                                        {redirecting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </div>
                                </CardContent>
                            </form>
                        </div>
                    </Card>
                </div>
            </div>
        </React.Fragment>)
};

// ---
function isMatchPwd(pwd1: string, pwd2: string, setError: Function) {
    if (pwd1 === pwd2) {
        setError({ type: ErrorType.undefined, text: '' })
    } else {
        setError({ type: ErrorType.error, text: Consts.pages.inviteRegisterPage.errorDifferentPwd })
    }
};

// ---
async function submitHandler(e: any, requestId: string, state: state, history: any, hashLocation: any, setError: Function, setRedirecting: Function) {
    e.preventDefault();
    let body: any = {};
    let url: string = '';
    if (state.pageType === PageType.invite) {
        body = {
            name: state.name,
            login: state.login,
            pwd: state.password
        };
        url = '/rest/v1/iam/invites/' + requestId;
    } else if (state.pageType === PageType.selfReg) {
        body = {
            pwd: state.password
        };
        url = '/rest/v1/iam/self_register_requests/' + requestId;
    };
    //
    const reqOptions = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(body)
    };
    const resp: Response = await fetch(url, reqOptions);
    let respBody: any = {};
    try {
        respBody = await resp.json();
    } catch {
        respBody['error_message'] = resp.statusText;
    };
    if (resp && resp.status >= 200 && resp.status <= 299) {
        let successText: string;
        if (state.pageType === PageType.selfReg) {
            successText = DataUtils.getSuccessTextByPage('registerSubmit', respBody.result_msg);
        } else {
            successText = DataUtils.getSuccessTextByPage('inviteSubmit', respBody.result_msg);
        };
        setError({ text: successText, type: ErrorType.ok });
        setRedirecting(true);
        setTimeout(() => {
            const user = respBody.user;
            let meetRefHashParam = '';
            if (hashLocation.state) {
                meetRefHashParam = '&meetreferrer=' + hashLocation.state;
            };
            history.push("/login?domain=" + user.domain + "&login=" + user.login + meetRefHashParam);
        }, 2000);
    } else {
        const errorMsg = DataUtils.getErrorTextByCode(respBody.error_details, respBody.error_message);
        setError({ text: errorMsg, type: ErrorType.error });
    }
};

export default InviteRegisterPage;