import AppModel from './AppModel';
import { types, Instance, flow, getParent } from 'mobx-state-tree';
import Consts from '../utils/consts';
import * as Utils from '../utils/utils';

const AppItems = types.array(AppModel);

export type IAppItems = Instance<typeof AppItems>;
export type fillResult = {
    isSuccess: boolean,
    data?: any
};

export type webapp = {
    name: string,
    nameConsts?: any,
    description: string,
    descriptionConsts?: any,
    url: string,
    order: number,
    'fa-icon': string,
    icon?: string
};

const AppStore = types.model({
    items: AppItems,
}).views(self => ({
    getApps: () => {
        return self.items;
    }
})).actions(self => ({
    fill: flow(function* fill() {
        const store: any = getParent(self);
        let result: fillResult = { isSuccess: false };

        const resp: any = yield fetch('/rest/v1/iam/sessions/current');
        let body: any = {}
        try {
            body = yield resp.json();
        } catch {
            store.setAuthorized(false);
            self.items.clear();
            return result;
        }
        yield Utils.trySessionRedirect(resp.status, body);

        if (!resp) {
            store.setAuthorized(false);
            self.items.clear();
            return result;
        }

        if (resp.status >= 300) {
            store.setAuthorized(false);
            self.items.clear();
            return result;
        }


        if (body['webapps'] === undefined) {
            store.setAuthorized(false);
            self.items.clear();
            return result;
        }

        yield Consts.initializeBySession(body);
        const language = Consts.language;

        store.setAuthorized(true);
        const apps = body['webapps'].map((item: webapp) => {
            // title
            let title = "";
            if (item.nameConsts && item.nameConsts[language]) {
                title = item.nameConsts[language];
            } else if (item.name) {
                title = item.name;
            };
            // hint
            let hint = "";
            if (item.descriptionConsts && item.descriptionConsts[language]) {
                hint = item.descriptionConsts[language];
            } else if (item.description) {
                hint = item.description;
            };
            // result
            const resultItem = {
                id: item.name + '_' + item.url,
                title: title,
                hint: hint,
                imgUrl: item['icon'] ? item['icon'] : item['fa-icon'] ? item['fa-icon'] : '',
                isIconFA: item['icon'] ? false : item['fa-icon'] ? true : false,
                appUrl: item.url ? item.url : "",
                order: item.order ? item.order : 10000,
                faIcon: item['fa-icon'] ? item['fa-icon'] : "fa-window-close"
            };
            return resultItem;
        });
        self.items = apps.sort(function (A: { order: number }, B: { order: number }) { return A.order - B.order });

        store.setSessionInfo(body);

        result.isSuccess = true;
        result.data = body;

        return result;
    })
}));

export default AppStore;