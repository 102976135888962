import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Theme, Card, CardContent, TextField, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import './RestorePwdPage.css';
import Consts from '../../utils/consts';
import { parseHashLocation } from '../../App'
import * as DataUtils from '../../stores/DataUtils';

// -----------
// Defines
// -----------

enum ErrorType {
    ok = 'ok',
    error = 'error',
    undefined = 'undefined'
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'fixed',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            backgroundColor: '#EAEAEA',
            overflow: 'auto'
            // border: '1px solid black',
        },
        container2: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            minHeight: 600,
            width: '90%',
            maxWidth: '400px',
            // border: '1px solid black',
        },
        card: {
            padding: '0 8px'
        },
        logo: {
            flex: '1 1 20%',
            paddingTop: 24,
            paddingLeft: 24,
            // border: '1px solid black',
        },
        logoImg: {
            padding: '0 0 0 0',
            margin: '0 0 0 0',
            maxHeight: 100,
            maxWidth: 200,
            // border: '1px solid black',
        },
        header: {
            marginTop: 16,
            paddingLeft: 24,
            paddingRight: 24,
            fontSize: '24px',
            // border: '1px solid green',
        },
        div: {
            // border: '1px solid red',
        },
        form: {
            padding: '0 0 0 0',
            // border: '1px solid black',
        },
        inner: {
            width: '200',
            display: 'flex',
            flexDirection: 'column',
            // border: '1px solid black',
        },
        textField: {
            marginLeft: 0,//theme.spacing(1),
            marginRight: 0,//theme.spacing(1),
            width: '100%',
        },
        linkField: {
            flex: '1',
            marginBottom: '0.5em',
            //marginLeft: 0,//theme.spacing(1),
            //marginRight: 0,//theme.spacing(1),
            color: 'blue'
        },
        error: {
            fontSize: '12px',
            color: 'red',
            marginLeft: 0
        },
        success: {
            fontSize: '12px',
            color: 'green',
            marginLeft: 0
        },
        divButton: {
            padding: '8 0',
            width: "100%",
            position: 'relative',
            marginBottom: '1em'
        },
        button: {
            width: '100%',
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -20,
        }
    }),
);

const RestorePwdPage: React.FC = () => {
    const classes = useStyles();
    //
    const location = useLocation();
    const hashLocation = parseHashLocation(location.search);
    //
    const [loginEmail, setloginEmail] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [domain, setDomain] = useState("");
    const [error, setError] = useState({ text: "", type: ErrorType.undefined });

    const logo = "/rest/v1/public/resources/logo";

    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.container2}>
                    <Card className={classes.card}>
                        <div className={classes.logo}><img src={logo} alt="l" className={classes.logoImg} /></div>
                        <div className={classes.header}>{Consts.pages.restorePwdPage.header}</div>
                        <div className={classes.div}>
                            <form className={classes.form} onSubmit={(e) => submitHandler(e, loginEmail, domain, hashLocation, setIsSuccess, setError)}>
                                <CardContent className={classes.inner} >
                                    <TextField
                                        required
                                        id="loginEmail"
                                        label={Consts.pages.restorePwdPage.loginEmail}
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={(data) => setloginEmail(data.target.value)}
                                    />
                                    <TextField
                                        id="domain"
                                        label={(loginEmail !== '' && !isEmail(loginEmail)) ? Consts.pages.restorePwdPage.domainRequired : Consts.pages.restorePwdPage.domainOptional}
                                        className={classes.textField}
                                        margin="normal"
                                        required={loginEmail !== '' && !isEmail(loginEmail)}
                                        onChange={(data) => setDomain(data.target.value)}
                                    />
                                    <div style={{ padding: '9px 0' }}>
                                        <p style={{ margin: '0' }} className={error.type === ErrorType.ok ? classes.success : classes.error}>{error.text}</p>
                                    </div>
                                    <div className={classes.divButton}>
                                        <Button variant="contained" disabled={loginEmail === '' || (!isEmail(loginEmail) && domain === '') || isSuccess} className={classes.button} type="submit">{Consts.pages.restorePwdPage.next}</Button>
                                    </div>
                                </CardContent>
                            </form>
                        </div>
                    </Card>
                </div>
            </div>
        </React.Fragment>)
};

// ---
async function submitHandler(e: any, loginEmail: string, domain: string, hashLocation: any, setIsSuccess: Function, setErrorText: Function) {
    e.preventDefault();
    let body: any = { key: loginEmail };
    if (domain) {
        body['domain'] = domain;
    };
    if (hashLocation.meetreferrer) {
        body.state = encodeURIComponent(hashLocation.meetreferrer);
    };
    //
    const reqOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(body)
    };
    const resp: Response = await fetch('/rest/v1/iam/pwd_reset_requests', reqOptions);
    const respBody = await resp.json();
    if (resp && resp.status >= 200 && resp.status <= 299) {
        setIsSuccess(true);
        const successText = DataUtils.getSuccessTextByPage('restorePwd', respBody.result_msg);
        setErrorText({ text: successText, type: ErrorType.ok });
    } else {
        const errorMsg = DataUtils.getErrorTextByCode(respBody.error_details, respBody.error_message);
        setErrorText({ text: errorMsg, type: ErrorType.error });
    }
};

// ---
function isEmail(email: string) {
    const isEmail = email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    return isEmail ? true : false;
};

export default RestorePwdPage;